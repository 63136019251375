import axios from "axios";
import { environment } from "../../environments/environment";
import { ResetSenhaRequestDTO } from "../../model/dto/request/ResetSenhaRequestDTO";

class ResetSenhaDataService {
  env = environment.getEnvironment();
  RESET_SENHA_ENDPOINT = `${this.env.BACKEND_API_URL}/reset-senha`;

  resetSenha(request: ResetSenhaRequestDTO) {
    return axios.post(`${this.RESET_SENHA_ENDPOINT}`, request, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}

const resetSenhaDataService = new ResetSenhaDataService();
export default resetSenhaDataService;
