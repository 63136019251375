import { formatData } from "../../../utils/FormatDataUtils";

export const ProntuariosPacienteHeader = [
  { field: "nrProntuario", header: "Nrº", size: 110 },
  { field: "tipoProntuario.nome", header: "Tipo de atendimento" },
  { field: "psicologo.nome", header: "Psicólogo" },
  { field: "isFinalizado", header: "Finalizado?", body: getIsRascunho },
  {
    field: "dataConsulta",
    header: "Data da consulta",
    body: getDataConsulta,
  },
];

function getIsRascunho(rowData) {
  if (rowData.isFinalizado) {
    return "Sim";
  } else {
    return "Não";
  }
}

function getDataConsulta(rowData) {
  if (rowData.dataConsulta !== null) {
    return formatData(rowData.dataConsulta);
  }
}
