import { forwardRef } from "react";
import { Col, Row } from "react-bootstrap";
import PRONPSI_LOGO from "../../../images/pronpsi-timbado-logo.png";
import { calcularIdade, formatData } from "../../../utils/FormatDataUtils";
import ProntuarioLabel from "../../../views/prontuario/components/ProntuarioLabel";
import "./PrintProntuario.css";

const PrintProntuario = forwardRef(({ dadosProntuario }, ref) => {
  return (
    <div style={{ display: "none" }}>
      <div className="PrintProntuario" ref={ref}>
        <table>
          <thead>
            <div className="PronpsiLogo">
              <img
                src={PRONPSI_LOGO}
                height={40}
                width={230}
                alt="cabecalhoPsicologando"
              ></img>
            </div>
            <div className="PronpsiPanelHeader">
              <label>
                <b>Prontuário Nº: </b>
                {dadosProntuario.nrProntuario}
              </label>
              <label>
                <b>Data da consulta: </b>
                {dadosProntuario.dataConsulta !== undefined
                  ? formatData(dadosProntuario.dataConsulta)
                  : ""}
              </label>
            </div>
            <div className="PronpsiInformacoesPaciente">
              <Row>
                <Col>
                  <ProntuarioLabel
                    titulo={"Paciente: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? dadosProntuario.paciente.paciente
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Nº Carteirinha: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? dadosProntuario.paciente.nrCarteirinha
                        : ""
                    }
                  />
                </Col>
              </Row>
            </div>
          </thead>
          <tbody>
            <div style={{ textAlign: "start", marginLeft: "40px" }}>
              <ProntuarioLabel
                underlineTitle={true}
                titulo={"Dados psicólogo:"}
              />
            </div>
            <div className="PronpsiBodyInfoPacinte">
              <Row>
                <Col>
                  <ProntuarioLabel
                    titulo={"Psicólogo: "}
                    valor={
                      dadosProntuario.psicologo
                        ? dadosProntuario.psicologo.nome
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"CRP: "}
                    valor={
                      dadosProntuario.psicologo
                        ? dadosProntuario.psicologo.crp
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Telefone: "}
                    valor={
                      dadosProntuario.psicologo
                        ? dadosProntuario.psicologo.telefone
                        : ""
                    }
                  />
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: "start", marginLeft: "40px" }}>
              <ProntuarioLabel
                underlineTitle={true}
                titulo={"Dados do paciente:"}
              />
            </div>
            <div className="PronpsiBodyInfoPacinte">
              <Row>
                <Col>
                  <ProntuarioLabel titulo={"Convênio: "} valor={"Sulamerica"} />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Telefone: "}
                    valor={
                      dadosProntuario.paciente
                        ? dadosProntuario.paciente.telefone
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Médico: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? dadosProntuario.paciente.medico.nome
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProntuarioLabel
                    titulo={"Data de nascimento: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? formatData(dadosProntuario.paciente.dataNascimento)
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Idade: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? calcularIdade(
                            dadosProntuario.paciente.dataNascimento
                          ) + " anos"
                        : ""
                    }
                  />
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <ProntuarioLabel
                    titulo={"Endereço: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? dadosProntuario.paciente.logradouro
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProntuarioLabel
                    titulo={"Bairro: "}
                    valor={
                      dadosProntuario.paciente !== undefined
                        ? dadosProntuario.paciente.bairro
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Cidade: "}
                    valor={
                      dadosProntuario.paciente !== undefined &&
                      dadosProntuario.paciente.cidade !== null &&
                      dadosProntuario.paciente.estado !== null
                        ? dadosProntuario.paciente.cidade +
                          "/" +
                          dadosProntuario.paciente.estado
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"Número: "}
                    valor={
                      dadosProntuario.paciente
                        ? dadosProntuario.paciente.numero
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <ProntuarioLabel
                    titulo={"CEP: "}
                    valor={
                      dadosProntuario.paciente
                        ? dadosProntuario.paciente.cep
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <ProntuarioLabel
                  titulo={"Queixa: "}
                  valor={
                    dadosProntuario.itemsQueixaInicial !== undefined &&
                    dadosProntuario.itemsQueixaInicial.length > 0
                      ? dadosProntuario.itemsQueixaInicial
                          .map((item) => item.nome)
                          .join(", ")
                      : ""
                  }
                />
              </Row>
            </div>
            <div className="PronpsiRegistroAtendimento">
              <div style={{ textAlign: "left" }}>
                <ProntuarioLabel
                  underlineTitle={true}
                  titulo={"Registros de atendimento: "}
                />
              </div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: dadosProntuario.registroAtendimento,
                }}
              />
              <div style={{ textAlign: "left" }}>
                <ProntuarioLabel
                  underlineTitle={true}
                  titulo={"Observações: "}
                />
              </div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: dadosProntuario.observacoes,
                }}
              />
              <div style={{ textAlign: "left" }}>
                <ProntuarioLabel underlineTitle={true} titulo={"Evolução: "} />
              </div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: dadosProntuario.evolucao,
                }}
              />
            </div>
          </tbody>
          <tfoot>
            <div className="PronpsiFooter">
              <p style={{ color: "#24345a" }}>
                PSICOLOGANDO - INSTITUTO DE SAÚDE MENTAL E EMOCIONAL - CNPJ
                42.113L.121/0001-90<br></br>
                Rua Albuquerque Lins, 77 A - Retiro da Mantiqueira, CEP:
                12.712-630 - Cruzeiro/SP<br></br>
                Tel. Whatsapp (12)9 9135-0419 - E-mail:
                psicologandoism@gmail.com
              </p>
            </div>
          </tfoot>
        </table>
      </div>
    </div>
  );
});

export default PrintProntuario;
