import { formatDataHora } from "../../../utils/FormatDataUtils";

export const RelatorioHistoricoAcessoPronpsiHeader = [
  { field: "usuario.nome", header: "Usuário", size: 100 },
  { field: "login", header: "Login" },
  { field: "statusAcesso", header: "Status do acesso" },
  {
    field: "dataHoraAcesso",
    header: "Data e hora do acesso",
    body: getDataHoraAcesso,
  },
];

function getDataHoraAcesso(rowData) {
  if (rowData.dataHoraAcesso) {
    return formatDataHora(rowData.dataHoraAcesso);
  }
}
