import resetSenhaDataService from "../data_services/reset-senha/ResetSenhaDataService";
import { ResetSenhaRequestDTO } from "../model/dto/request/ResetSenhaRequestDTO";

class ResetSenhaService {
  public static async resetarSenha(resetSenhaRequestDTO: any) {
    await resetSenhaDataService.resetSenha(
      this.montaRequest(resetSenhaRequestDTO)
    );
  }

  private static montaRequest(resetSenhaRequestDTO: ResetSenhaRequestDTO) {
    return new ResetSenhaRequestDTO({
      email: resetSenhaRequestDTO.email,
      captchaToken: resetSenhaRequestDTO.captchaToken,
    });
  }
}

export default ResetSenhaService;
