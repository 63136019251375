import { formatData, formatDataHora } from "../../../utils/FormatDataUtils";

export const RelatorioHistoricoAtendimentosHeader = [
  { field: "nrProntuario", header: "Nº prontuário" },
  { field: "tipoProntuario.nome", header: "Tipo atendimento" },
  { field: "paciente.paciente", header: "Nome paciente" },
  { field: "paciente.nrCarteirinha", header: "Nº carteirinha" },
  { field: "psicologo.nome", header: "Nome psicólogo" },
  { field: "psicologo.crp", header: "CRP" },
  {
    field: "isPrimeiraSessao",
    header: "Primeira sessão?",
    body: getIsPrimeiraSessao,
  },
  {
    field: "isFinalizado",
    header: "Atendimento finalizado?",
    body: getIsFinalizado,
  },
  {
    field: "dataHoraCriacao",
    header: "Data criação prontuário",
    body: getDataAtendimento,
  },
  {
    field: "dataHoraUltimaAtualizacao",
    header: "Data hora ult. atualização",
    body: getDataHoraUltimaAtualizacao,
  },
  {
    field: "dataConsulta",
    header: "Data consulta",
    body: getDataConsulta,
  },
];

function getIsPrimeiraSessao(rowData) {
  if (rowData.isPrimeiraSessao) {
    return "Sim";
  } else {
    return "Não";
  }
}

function getIsFinalizado(rowData) {
  if (rowData.isFinalizado) {
    return "Sim";
  } else {
    return "Não";
  }
}

function getDataAtendimento(rowData) {
  return formatData(rowData.dataHoraCriacao);
}

function getDataHoraUltimaAtualizacao(rowData) {
  if (rowData.dataHoraUltimaAtualizacao) {
    return formatDataHora(rowData.dataHoraUltimaAtualizacao);
  }
}

function getDataConsulta(rowData) {
  if (rowData.dataConsulta) {
    return formatData(rowData.dataConsulta);
  }
}
