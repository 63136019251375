import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { LogAcessoSistemaFiltrosRequestDTO } from "../../model/dto/request/LogAcessoSistemaFiltrosRequestDTO";

class LogAcessoSistemaDataService {
  env = environment.getEnvironment();
  LOG_ACESSO_SISTEMA_ENDPOINTS = `${this.env.BACKEND_API_URL}/monitoramento/log-acesso`;
  token = storageTokenConfig.getToken();

  getAcessos(
    size: number,
    page: number,
    filters?: LogAcessoSistemaFiltrosRequestDTO
  ) {
    return axios.post(
      `${this.LOG_ACESSO_SISTEMA_ENDPOINTS}/?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const logAcessoSistemaDataService = new LogAcessoSistemaDataService();
export default logAcessoSistemaDataService;
