export class RelatorioHistoricoAcessoPronpsiExportDTO {
  "Usuário"?: string;
  "Login"?: string;
  "Status do acesso"?: string;
  "Data e hora"?: string;

  constructor(options: Partial<RelatorioHistoricoAcessoPronpsiExportDTO> = {}) {
    this["Usuário"] = options["Usuário"];
    this["Login"] = options["Login"];
    this["Status do acesso"] = options["Status do acesso"];
    this["Data e hora"] = options["Data e hora"];
  }
}
