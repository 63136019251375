import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DefaultButton from "../components/buttons/DefaultButton";
import LOGO_SISTEMA_SVG from "../images/CORACAO_PSICOLOGANDO.svg";
import { useEffect, useState } from "react";
import packageJson from "../../package.json";
import "./BemVindo.css";
import { InfoIcon } from "../components/icons/Icons";
import updates from "../config/Versions";

export default function BemVindo() {
  const history = useHistory();

  function navigate(url) {
    history.push(url);
  }

  const handleAccessClick = () => {
    navigate("/login");
  };

  const [showContent, setShowContent] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [visibleUpdates, setVisibleUpdates] = useState(5);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleShowMore = () => {
    setVisibleUpdates((prevVisible) => prevVisible + 5);
  };

  return (
    <div className="welcome-page">
      <header className={`welcome-header ${showContent ? "show" : ""}`}>
        <Container style={{ marginTop: 50 }}>
          <Row className="justify-content-center align-items-center">
            <Col md="auto" className="text-center">
              <img
                alt="logo-pronpsi-bem-vindo"
                className="heartbeat-logo"
                width={325}
                src={LOGO_SISTEMA_SVG}
              />
            </Col>
            <Col md="auto" className="text-center">
              <h2 className="system-description">
                <b>PRONPSi</b> - Sistema de gerenciamento de prontuários e
                pacientes
              </h2>
            </Col>
            <Col md="auto" className="text-right">
              <div
                className="info-icon"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <InfoIcon size="2x" />
                {showTooltip && (
                  <div className="tooltip-box">
                    <h5>
                      <u>Notas de Atualização</u>
                    </h5>
                    <ul>
                      {updates.slice(0, visibleUpdates).map((update, index) => (
                        <li key={index}>
                          <strong>v{update.version}:</strong>{" "}
                          {update.description}
                        </li>
                      ))}
                    </ul>
                    {visibleUpdates < updates.length && (
                      <button
                        onClick={handleShowMore}
                        className="show-more-btn"
                      >
                        Ver mais
                      </button>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md="auto">
              <DefaultButton
                fontSize={12}
                buttonLabel="Acessar o PRONPSi"
                labelColor="#5685a5"
                backgroundColor="#fff"
                borderColor="#24345a"
                hoverColor="#24345a"
                onHoverBorderColor="#fff"
                onClickColor="#1e1f26"
                onClickBorderColor="#cc2a36"
                width={200}
                height={40}
                messageToolTip="Entrar no sistema"
                click={handleAccessClick}
              />
            </Col>
          </Row>
        </Container>
      </header>
      <footer className="footer">
        <Container className="text-center">
          <Row>
            <Col>
              <p>
                v{packageJson.version} - COUTINHO INNOVATIONS - Digital
                Consulting™{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}
