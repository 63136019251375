import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { FiltrarIcon, LimparIcon } from "../../../components/icons/Icons";
import message from "../../../components/Messages";
import CustomPaginateDataTable from "../../../components/table/CustomPaginateDataTable";
import { LogAcessoSistemaFiltrosRequestDTO } from "../../../model/dto/request/LogAcessoSistemaFiltrosRequestDTO";
import { UsuarioInfoDTO } from "../../../model/dto/UsuarioInfoDTO";
import StatusAcessoSistemaEnum from "../../../model/enums/StatusAcessoSistemaEnum";
import LogAcessoSistemaService from "../../../service/LogAcessoSistemaService";
import RelatorioService from "../../../service/RelatorioService";
import { exportRelatorioHistoricoAcessoPronpsi } from "../../../utils/excel/ExportRelatorioHistoricoAcessoPronpsi";
import "./RelatorioHistoricoAcessoPronpsi.css";
import { RelatorioHistoricoAcessoPronpsiHeader } from "./RelatorioHistoricoAcessoPronpsiHeader";

export default function RelatorioHistoricoAcessoPronpsi({ usuarios }) {
  const statusOptions = Object.keys(StatusAcessoSistemaEnum).map((key) => ({
    label: StatusAcessoSistemaEnum[key],
    value: key,
  }));
  const [blockUi, setBlockUi] = useState(false);
  const [dados, setDados] = useState([]);
  const [totalDados, setTotalDados] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);

  const [filtros, setFiltros] = useState(
    new LogAcessoSistemaFiltrosRequestDTO()
  );

  const [filtroUsuarioSelecionado, setFiltroUsuarioSelecionado] = useState(
    new UsuarioInfoDTO()
  );

  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState(null);

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  async function filtrarRelatorio(size, page) {
    setBlockUi(true);
    LogAcessoSistemaService.getAcessos(size, page, filtros).then((response) => {
      if (response.content.length === 0) {
        message.errorMessage(
          "Atenção!",
          "Não existem registros de acordo com os filtros informados!"
        );
        setBlockUi(false);
        limpar();
      } else {
        setDados(response.content);
        setTotalDados(response.totalElements);
        setBlockUi(false);
      }
    });
    setBlockUi(false);
  }

  function limpar() {
    setFiltros(new LogAcessoSistemaFiltrosRequestDTO());
    setFiltroUsuarioSelecionado(new UsuarioInfoDTO());
    setFiltroStatusSelecionado(null);
    setLinhasPorPagina(10);
    setPagina(0);
    setDados([]);
    setTotalDados(0);
  }

  return (
    <>
      <BlockUI
        width={"100%"}
        template={
          <ProgressSpinner
            strokeWidth="8"
            className="blue-spinner"
            style={{ width: "40px" }}
          />
        }
        blocked={blockUi}
      >
        <div className="RelatorioHistoricoAcessoPronpsi">
          <h4>Relatório de histórico de acesso ao PRONPSi</h4>
          <Container>
            <Row>
              <Col>
                <label htmlFor="usuarioFilter">Usuário: &nbsp;&nbsp;</label>
                <Dropdown
                  filter
                  className="FiltroUsuario"
                  placeholder="Selecione um usuário"
                  name="usuarioFilter"
                  id="usuarioFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroUsuarioSelecionado?.id !== undefined
                      ? filtroUsuarioSelecionado
                      : {}
                  }
                  options={usuarios}
                  optionLabel="nome"
                  emptyMessage="Não há usuários"
                  onChange={(e) => {
                    setFiltroUsuarioSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      usuarioId: e.target.value.id,
                    });
                  }}
                />
              </Col>

              <Col>
                <label htmlFor="dataAcessoInicioFilter">
                  Data acesso (de): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataAcessoInicio !== undefined
                      ? filtros.dataAcessoInicio
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataAcessoInicio: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="dataAcessoFimFilter">
                  Data acesso (até): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataAcessoFim !== undefined
                      ? filtros.dataAcessoFim
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataAcessoFim: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="statusAcessoFilter">
                  Status de acesso: &nbsp;&nbsp;
                </label>
                <Dropdown
                  filter
                  className="FiltroStatusAcesso"
                  placeholder="Selecione um status"
                  name="statusAcessoFilter"
                  id="statusAcessoFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtros?.statusAcesso !== undefined
                      ? filtroStatusSelecionado
                      : null
                  }
                  options={statusOptions}
                  optionLabel="label"
                  emptyMessage="Não há status"
                  onChange={(e) => {
                    setFiltroStatusSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      statusAcesso: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <div className="FilterRelatorioHistoricoAcessoPronpsi">
              <DefaultButton
                fontSize={12}
                icon={<FiltrarIcon />}
                buttonLabel={"Buscar"}
                messageToolTip={
                  "Busca os resultados de acordo com os parâmetros"
                }
                click={(_) => {
                  filtrarRelatorio(linhasPorPagina, pagina);
                }}
                width={150}
                height={30}
              />{" "}
              <DefaultButton
                fontSize={12}
                icon={<LimparIcon />}
                buttonLabel={"Limpar"}
                messageToolTip={"Limpa os filtros e os dados da tabela"}
                click={limpar}
                width={150}
                height={30}
              />{" "}
            </div>
          </Container>
          {totalDados > 0 && (
            <CustomPaginateDataTable
              exportar={true}
              dadosParaExportacao={RelatorioService.montaObjetoParaExportacaoRelatorioHistoricoAcessoPronpsi(
                dados
              )}
              exportFunction={exportRelatorioHistoricoAcessoPronpsi}
              values={dados}
              totalRecords={totalDados}
              dataKey="id"
              headerList={RelatorioHistoricoAcessoPronpsiHeader}
              actionTemplate={null}
              paginator={true}
              exibeActionTemplate={false}
              callbackFunction={filtrarRelatorio}
              linhasPorPagina={linhasPorPagina}
              setLinhasPorPagina={setLinhasPorPaginaCallback}
              setPagina={setPaginaCallback}
              fieldToSort={"dataHoraAcesso"}
              showInLeft={true}
            />
          )}
        </div>
      </BlockUI>
    </>
  );
}
