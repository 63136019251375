/* eslint-disable react-hooks/exhaustive-deps */
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import message from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import {
  FiltrarIcon,
  IniciarNovoAtendimentoIcon,
  LimparIcon,
  MeusAtendimentosIcon,
} from "../../components/icons/Icons";
import PrintProntuario from "../../components/print/prontuario/PrintProntuario";
import CustomPaginateDataTable from "../../components/table/CustomPaginateDataTable";
import { MeuPacienteDTO } from "../../model/dto/MeuPacienteDTO";
import { MeusPacientesIdNomeDTO } from "../../model/dto/MeusPacientesIdNomeDTO";
import { ProntuarioDTO } from "../../model/dto/ProntuarioDTO";
import { TipoProntuarioDTO } from "../../model/dto/TipoProntuarioDTO";
import { MeusAtendimentosFilteredRequestDTO } from "../../model/dto/request/MeusAtendimentosFilteredRequestDTO";
import MedicoService from "../../service/MedicoService";
import MeusAtendimentosService from "../../service/MeusAtendimentosService";
import MeusPacientesService from "../../service/MeusPacientesService";
import ProntuarioService from "../../service/ProntuarioService";
import TipoProntuarioService from "../../service/TipoProntuarioService";
import { formatData } from "../../utils/FormatDataUtils";
import DialogVisualizaPaciente from "../paciente/dialog/DialogVisualizaPaciente";
import "./MeusAtendimentos.css";
import DialogIniciarNovoAtendimento from "./dialog/DialogIniciarNovoAtendimento";
import DialogMeuAtendimento from "./dialog/DialogMeuAtendimento";
import { MeusAtendimentosHeader } from "./header/MeusAtendimentosHeader";

export default function MeusAtendimentos() {
  const history = useHistory();
  const atendimentoRef = useRef();
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [blockUi, setBlockUi] = useState(false);
  const [filtros, setFiltros] = useState(
    new MeusAtendimentosFilteredRequestDTO()
  );
  const [tiposProntuario, setTiposProntuario] = useState([]);
  const [meusPacientes, setMeusPacientes] = useState([]);
  const [medicos, setMedicos] = useState([]);

  const [filtroTipoProntuarioSelecionado, setFiltroTipoProntuarioSelecionado] =
    useState(new TipoProntuarioDTO());
  const [filtroPacienteSelecionado, setFiltroPacienteSelecionado] = useState(
    new MeusPacientesIdNomeDTO()
  );

  const [meusAtendimentos, setMeusAtendimentos] = useState([]);
  const [totalMeusAtendimentos, setTotalMeusAtendimentos] = useState(0);
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState(
    new ProntuarioDTO()
  );
  const [pacienteSelecionado, setPacienteSelecionado] = useState(
    new MeuPacienteDTO()
  );
  const [pacienteIdNomeSelecionado, setPacienteIdNomeSelecionado] = useState(
    new MeusPacientesIdNomeDTO()
  );

  const [displayModalOpcoes, setDisplayModalOpcoes] = useState(false);
  const [displayModalPaciente, setDisplayModalPaciente] = useState(false);
  const [displayModalNovoAtendimento, setDisplayModalNovoAtendimento] =
    useState(false);

  const statusIsPrimeiraSessao = [
    { nome: "Todos", valor: null },
    { nome: "Sim", valor: true },
    { nome: "Não", valor: false },
  ];

  const [isPrimeiraSessaoSelecionada, setIsPrimeiraSessaoSelecionada] =
    useState(null);

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  const setPacienteIdNomeSelecionadoCallback = useCallback(
    function (p) {
      setPacienteIdNomeSelecionado(p);
    },
    [setPacienteIdNomeSelecionado]
  );

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  useEffect(() => {
    carregarDadosIniciais();
    carregarMeusAtendimentos();
  }, []);

  function navigate(url) {
    history.push(url);
  }

  async function carregarMeusAtendimentos() {
    setBlockUi(true);
    MeusAtendimentosService.getMeusAtendimentos(10, pagina, null)
      .then((response) => {
        if (response.content.length === 0) {
          message.errorMessage(
            "Atenção!",
            "Nenhum atendimento encontrado no seu cadastro!"
          );
          setBlockUi(false);
        } else {
          setMeusAtendimentos(response.content);
          setTotalMeusAtendimentos(response.totalElements);
          setBlockUi(false);
        }
      })
      .catch((error) => {
        message.errorMessage("Ops ...", error.response.data.message);
        setBlockUi(false);
      });
  }

  async function carregarDadosIniciais() {
    setBlockUi(true);
    const [tiposProntuarioData, meusPacientesData, medicosData] =
      await Promise.all([
        TipoProntuarioService.getAllTiposProntuario(),
        MeusPacientesService.getMeusPacientesIdNome(),
        MedicoService.getAll(),
      ]);
    setTiposProntuario(tiposProntuarioData);
    setMeusPacientes(meusPacientesData);
    setMedicos(medicosData);
    setBlockUi(false);
  }

  async function limpar() {
    setFiltroTipoProntuarioSelecionado(new TipoProntuarioDTO());
    setFiltroPacienteSelecionado(new MeusPacientesIdNomeDTO());
    setFiltros(new MeusAtendimentosFilteredRequestDTO());
    setLinhasPorPagina(10);
    setPagina(0);
    carregarDadosIniciais();
    carregarMeusAtendimentos();
  }

  async function filtrarMeusAtendimentos(size, page) {
    setBlockUi(true);
    MeusAtendimentosService.getMeusAtendimentos(size, page, filtros)
      .then((response) => {
        if (response.content.length === 0) {
          message.errorMessage(
            "Atenção!",
            "Não existem registros de acordo com os filtros informados!"
          );
          setBlockUi(false);
          limpar();
        } else {
          setMeusAtendimentos(response.content);
          setTotalMeusAtendimentos(response.totalElements);
          setBlockUi(false);
        }
      })
      .catch((error) => {
        message.errorMessage("Ops ...", error.response.data.message);
        setBlockUi(false);
      });
  }

  function fecharModalOpcoes() {
    setDisplayModalOpcoes(false);
  }

  function fecharModalPaciente() {
    setDisplayModalPaciente(false);
  }

  function fecharModalIniciarNovoAtendimento() {
    setDisplayModalNovoAtendimento(false);
  }

  function onClickLinhaAtendimento(rowData) {
    setAtendimentoSelecionado(rowData.value);
    setPacienteSelecionado(rowData.value.paciente);
    setDisplayModalOpcoes(true);
  }

  function visualizarDadosDoPacienteClick(rowData) {
    setPacienteSelecionado(rowData);
    setDisplayModalOpcoes(false);
    setDisplayModalPaciente(true);
  }

  function visualizarDadosDoAtendimento(rowData) {
    setPacienteSelecionado(rowData);
  }

  function editarDadosAtendimento(rowData) {
    setPacienteSelecionado(rowData);
  }

  async function iniciarAtendimento(tipoProntuario) {
    setBlockUi(true);
    try {
      const response = await ProntuarioService.criarProntuario(
        tipoProntuario.id,
        pacienteIdNomeSelecionado.id
      );
      fecharModalIniciarNovoAtendimento();
      setBlockUi(false);
      navigate(`/prontuario/cadastrar/${response.id}/editar`, {
        isVisualizacao: false,
      });
    } catch (error) {
      fecharModalIniciarNovoAtendimento();
      message.errorMessage("Opss...", error.response.data.message);
      setBlockUi(false);
    }
    setBlockUi(false);
  }

  function iniciarNovoAtendimentoClick() {
    setDisplayModalNovoAtendimento(true);
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="MeusAtendimentos">
        <div className="Title">
          <span>
            <MeusAtendimentosIcon /> Meus atendimentos
          </span>
        </div>
      </div>
      <div className="BodyMeusAtendimentos">
        <Row>
          <Col>
            <label htmlFor="dataConsultaInicialFilter">
              Data consulta (de): &nbsp;&nbsp;
            </label>
            <InputText
              style={{ width: "100%", textAlign: "center" }}
              type="date"
              value={
                filtros?.dataAtendimentoInicial !== undefined
                  ? filtros.dataAtendimentoInicial
                  : ""
              }
              onChange={(e) =>
                setFiltros({
                  ...filtros,
                  dataAtendimentoInicial: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            <label htmlFor="dataConsultaFinalFilter">
              Data consulta (até): &nbsp;&nbsp;
            </label>
            <InputText
              style={{ width: "100%", textAlign: "center" }}
              type="date"
              value={
                filtros?.dataAtendimentoFinal !== undefined
                  ? filtros.dataAtendimentoFinal
                  : ""
              }
              onChange={(e) =>
                setFiltros({
                  ...filtros,
                  dataAtendimentoFinal: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            <label htmlFor="tipoProntuarioFilter">
              Tipo de atendimento: &nbsp;&nbsp;
            </label>
            <Dropdown
              placeholder="Selecione um tipo de atendimento"
              name="tipoProntuarioFilter"
              id="tipoProntuarioFilter"
              style={{
                width: "100%",
                height: "50%",
                background: "#7988ac",
              }}
              value={
                filtroTipoProntuarioSelecionado.id !== undefined
                  ? filtroTipoProntuarioSelecionado
                  : {}
              }
              options={tiposProntuario}
              optionLabel="nome"
              emptyMessage="Não há informações"
              onChange={(e) => {
                setFiltroTipoProntuarioSelecionado(e.target.value);
                setFiltros({
                  ...filtros,
                  tipoAtendimentoId: e.target.value.id,
                });
              }}
            />
          </Col>
          <Col>
            <label htmlFor="pacienteFilter">Paciente: &nbsp;&nbsp;</label>
            <Dropdown
              placeholder="Selecione um paciente"
              filter
              name="pacienteFilter"
              id="pacienteFilter"
              style={{
                width: "100%",
                height: "50%",
                background: "#7988ac",
              }}
              value={
                filtroPacienteSelecionado.id !== undefined
                  ? filtroPacienteSelecionado
                  : {}
              }
              options={meusPacientes}
              optionLabel="paciente"
              emptyMessage="Não há informações"
              onChange={(e) => {
                setFiltroPacienteSelecionado(e.target.value);
                setFiltros({
                  ...filtros,
                  pacienteId: e.target.value.id,
                });
              }}
            />
          </Col>
          <Col>
            <label htmlFor="primeiraSessaoFilter">
              Primeira sessão?: &nbsp;&nbsp;
            </label>
            <Dropdown
              placeholder="Selecione um status"
              name="primeiraSessaoFilter"
              id="primeiraSessaoFilter"
              style={{ width: "100%", background: "#7988ac" }}
              value={isPrimeiraSessaoSelecionada}
              options={statusIsPrimeiraSessao}
              optionLabel="nome"
              onChange={(e) => {
                setIsPrimeiraSessaoSelecionada(e.value);
                setFiltros({
                  ...filtros,
                  isPrimeiraSessao: e.value.valor,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <div className="FilterMeusAtendimentosItens">
            <DefaultButton
              fontSize={12}
              icon={<FiltrarIcon />}
              buttonLabel={"Filtrar"}
              messageToolTip={
                "Filtra os resultados de acordo com os parâmetros"
              }
              click={(_) => {
                filtrarMeusAtendimentos(linhasPorPagina, pagina);
              }}
              width={150}
              height={30}
            />{" "}
            <DefaultButton
              fontSize={12}
              icon={<LimparIcon />}
              buttonLabel={"Limpar"}
              messageToolTip={"Limpar os filtros"}
              click={limpar}
              width={150}
              height={30}
            />{" "}
          </div>
        </Row>

        <Row>
          <div className="FilterMeusAtendimentosItens">
            <DefaultButton
              fontSize={12}
              icon={<IniciarNovoAtendimentoIcon />}
              buttonLabel={"Novo atendimento"}
              messageToolTip={"Inicia novo atendimento"}
              click={iniciarNovoAtendimentoClick}
              width={200}
              height={30}
            />{" "}
          </div>
        </Row>
        <CustomPaginateDataTable
          values={meusAtendimentos}
          totalRecords={totalMeusAtendimentos}
          dataKey="id"
          headerList={MeusAtendimentosHeader}
          actionTemplate={null}
          paginator={true}
          exibeActionTemplate={false}
          callbackFunction={filtrarMeusAtendimentos}
          linhasPorPagina={linhasPorPagina}
          setLinhasPorPagina={setLinhasPorPaginaCallback}
          setPagina={setPaginaCallback}
          fieldToSort={"dataCriacao"}
          showInLeft={true}
          callbackSelectionChange={onClickLinhaAtendimento}
        />
        <DialogMeuAtendimento
          display={displayModalOpcoes}
          esconder={fecharModalOpcoes}
          paciente={
            atendimentoSelecionado !== undefined
              ? atendimentoSelecionado.paciente
              : null
          }
          setVisualizarDadosPaciente={visualizarDadosDoPacienteClick}
          tituloModal={`Prontuário Nº ${
            atendimentoSelecionado.nrProntuario
          } - Data: ${formatData(atendimentoSelecionado.dataHoraCriacao)} - ${
            atendimentoSelecionado?.paciente?.paciente
          }`}
          visualizarDadosAtendimento={visualizarDadosDoAtendimento}
          hrefVisualizarAtendimento={`/prontuario/cadastrar/${atendimentoSelecionado.id}/visualizar`}
          editarDadosAtendimento={editarDadosAtendimento}
          hrefEditarAtendimento={`/prontuario/cadastrar/${atendimentoSelecionado.id}/editar`}
          imprimirRef={atendimentoRef}
          imprimirTituloArquivo={
            atendimentoSelecionado !== undefined &&
            atendimentoSelecionado.paciente !== undefined
              ? `${
                  atendimentoSelecionado.nrProntuario
                }_${atendimentoSelecionado.paciente?.paciente.toUpperCase()}`
              : ""
          }
          callbackFunction={null}
          prontuario={atendimentoSelecionado}
        />
        <DialogVisualizaPaciente
          display={displayModalPaciente}
          esconder={fecharModalPaciente}
          paciente={
            pacienteSelecionado !== undefined &&
            pacienteSelecionado.paciente !== undefined
              ? pacienteSelecionado
              : {}
          }
          tituloModal={
            pacienteSelecionado !== undefined &&
            pacienteSelecionado.paciente !== undefined
              ? pacienteSelecionado.paciente
              : ""
          }
          medicos={medicos}
          visualizaHistorico={false}
        />
        <DialogIniciarNovoAtendimento
          display={displayModalNovoAtendimento}
          esconder={fecharModalIniciarNovoAtendimento}
          tiposProntuario={tiposProntuario}
          pacientes={meusPacientes}
          pacienteIdNomeSelecionado={pacienteIdNomeSelecionado}
          setPacienteIdNomeSelecionado={setPacienteIdNomeSelecionadoCallback}
          iniciarAtendimento={iniciarAtendimento}
        />
      </div>
      <PrintProntuario
        ref={atendimentoRef}
        dadosProntuario={atendimentoSelecionado}
      />
    </BlockUI>
  );
}
