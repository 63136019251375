import logAcessoSistemaDataService from "../data_services/log-acesso-sistema/LogAcessoSistemaDataService";
import { LogAcessoSistemaDTO } from "../model/dto/LogAcessoSistemaDTO";
import { LogAcessoSistemaFiltrosRequestDTO } from "../model/dto/request/LogAcessoSistemaFiltrosRequestDTO";

class LogAcessoSistemaService {
  static async getAcessos(
    size?: number,
    page?: number,
    filters?: LogAcessoSistemaFiltrosRequestDTO
  ): Promise<LogAcessoSistemaDTO[]> {
    if (!filters) {
      filters = new LogAcessoSistemaFiltrosRequestDTO({});
    }
    if (!size) {
      size = 10;
    }

    if (!page) {
      page = 0;
    }

    const response = await logAcessoSistemaDataService.getAcessos(
      size,
      page,
      filters
    );
    const acessos: LogAcessoSistemaDTO[] = response.data;
    return acessos;
  }
}

export default LogAcessoSistemaService;
