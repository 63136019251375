/* eslint-disable react-hooks/exhaustive-deps */
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import message from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import {
  EditarProntuarioIcon,
  FiltrarIcon,
  LimparIcon,
  ListaAtendimentosIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import PrintProntuario from "../../components/print/prontuario/PrintProntuario";
import CustomPaginateDataTable from "../../components/table/CustomPaginateDataTable";
import { PacienteDTO } from "../../model/dto/PacienteDTO";
import { ProntuarioDTO } from "../../model/dto/ProntuarioDTO";
import { TipoProntuarioDTO } from "../../model/dto/TipoProntuarioDTO";
import { ProntuarioPacienteFilteredRequestDTO } from "../../model/dto/request/ProntuarioPacienteFilteredRequestDTO";
import ProntuarioService from "../../service/ProntuarioService";
import TipoProntuarioService from "../../service/TipoProntuarioService";
import "./Prontuario.css";
import ProntuariosPacienteActionButtons from "./actions/ProntuariosPacienteActionButtons";
import { ProntuariosPacienteHeader } from "./header/ProntuariosPacienteHeader";

const DOIS_SEGUNDOS = 2000;

export default function ProntuariosPaciente() {
  const quillRef = useRef();
  const history = useHistory();
  const { pacienteId } = useParams();

  const [blockUi, setBlockUi] = useState(false);
  const [paciente, setPaciente] = useState(new PacienteDTO());
  const [atendimentosDoPaciente, setAtendimentosDoPaciente] = useState([]);
  const [totalAtendimentosDoPaciente, setTotalAtendimentosDoPaciente] =
    useState(0);
  const [prontuarioPacienteSelecionado, setProntuarioPacienteSelecionado] =
    useState(new ProntuarioDTO());
  const [tiposProntuario, setTiposProntuario] = useState([]);

  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [filtros, setFiltros] = useState(
    new ProntuarioPacienteFilteredRequestDTO()
  );

  const [filtroTipoProntuarioSelecionado, setFiltroTipoProntuarioSelecionado] =
    useState(new TipoProntuarioDTO());
  const [statusProntuarioSelecionado, setStatusProntuarioSelecionado] =
    useState(null);

  const status = [
    { nome: "Todos", valor: null },
    { nome: "Finalizado", valor: true },
    { nome: "Não finalizado (Rascunho)", valor: false },
  ];

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  function navigate(url, state) {
    history.push(url, state);
  }

  useEffect(() => {
    carregarAtendimentosDoPaciente();
    carregarTiposProntuario();
  }, []);

  async function carregarTiposProntuario() {
    setBlockUi(true);
    const [tiposProntuarioData] = await Promise.all([
      TipoProntuarioService.getAllTiposProntuario(),
    ]);
    setTiposProntuario(tiposProntuarioData);
    setBlockUi(false);
  }

  function carregarAtendimentosDoPaciente() {
    setBlockUi(true);
    ProntuarioService.getProntuariosByPacienteId(pacienteId, 10, pagina, null)
      .then((response) => {
        if (response.content.length === 0) {
          message.errorMessage(
            "Atenção!",
            "Não existem registros de atendimento deste paciente!"
          );
          setBlockUi(false);
          setTimeout(() => {
            navigate("/meus-pacientes");
          }, DOIS_SEGUNDOS);
        } else {
          setPaciente(response.content[0].paciente);
          setAtendimentosDoPaciente(response.content);
          setTotalAtendimentosDoPaciente(response.totalElements);
          setBlockUi(false);
        }
      })
      .catch((error) => {
        message.errorMessage("Ops ...", error.response.data.message);
        setBlockUi(false);
      });
  }

  async function filtrarAtendimentosPaciente(size, page) {
    setBlockUi(true);
    ProntuarioService.getProntuariosByPacienteId(
      pacienteId,
      size,
      page,
      filtros
    )
      .then((response) => {
        if (response.content.length === 0) {
          message.errorMessage(
            "Atenção!",
            "Não existem registros de acordo com os filtros informados!"
          );
          setBlockUi(false);
          limpar();
        } else {
          setPaciente(response.content[0].paciente);
          setAtendimentosDoPaciente(response.content);
          setTotalAtendimentosDoPaciente(response.totalElements);
          setBlockUi(false);
        }
      })
      .catch((error) => {
        message.errorMessage("Ops ...", error.response.data.message);
        setBlockUi(false);
      });
  }

  async function carregarProntuarioPacienteSelecionado(rowData) {
    setBlockUi(true);
    try {
      const [prontuarioData] = await Promise.all([
        ProntuarioService.getProntuarioById(rowData.id),
      ]);
      setProntuarioPacienteSelecionado(prontuarioData);
      setBlockUi(false);
    } catch (error) {
      message.errorMessage("Opss ...", error.response.data.message);
      navigate("/meus-pacientes");
      return false;
    }
    setBlockUi(false);
    return true;
  }

  function visualizarProntuarioPaciente(rowData) {
    navigate(`/prontuario/cadastrar/${rowData.id}/visualizar`);
  }

  function editarProntuarioPaciente(rowData) {
    navigate(`/prontuario/cadastrar/${rowData.id}/editar`);
  }

  function actionTemplate(rowData) {
    return (
      <ProntuariosPacienteActionButtons
        visualizarProntuarioIcon={<ViewIcon />}
        visualizarProntuarioMessageTooltip={"Visualizar este prontuário"}
        visualizarProntuarioClickAction={(_) =>
          visualizarProntuarioPaciente(rowData)
        }
        visualizarProntuarioHref={`/prontuario/cadastrar/${rowData.id}/visualizar`}
        editarProntuarioIcon={<EditarProntuarioIcon />}
        editarProntuarioMessageToolTip={"Editar este prontuário"}
        editarProntuarioClickAction={(_) => editarProntuarioPaciente(rowData)}
        editarProntuarioHref={`/prontuario/cadastrar/${rowData.id}/editar`}
        imprimirRef={quillRef}
        imprimirTituloArquivo={
          prontuarioPacienteSelecionado !== undefined &&
          prontuarioPacienteSelecionado.paciente !== undefined
            ? `${
                prontuarioPacienteSelecionado.nrProntuario
              }_${prontuarioPacienteSelecionado.paciente.paciente.toUpperCase()}`
            : ""
        }
        prontuario={prontuarioPacienteSelecionado}
        callbackFunction={(_) => carregarProntuarioPacienteSelecionado(rowData)}
      />
    );
  }

  async function limpar() {
    setFiltroTipoProntuarioSelecionado(new TipoProntuarioDTO());
    setFiltros(new ProntuarioPacienteFilteredRequestDTO());
    setLinhasPorPagina(10);
    setPagina(0);
    carregarTiposProntuario();
    carregarAtendimentosDoPaciente();
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Prontuario">
        <div className="Title">
          <span>
            <ListaAtendimentosIcon /> Lista de atendimentos de{" "}
            {paciente !== undefined ? paciente.paciente : ""}
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        <Row>
          <Col>
            <label htmlFor="dataConsultaInicialFilter">
              Data consulta (de): &nbsp;&nbsp;
            </label>
            <InputText
              style={{ width: "100%", textAlign: "center" }}
              type="date"
              value={
                filtros?.dataConsultaInicial !== undefined
                  ? filtros.dataConsultaInicial
                  : ""
              }
              onChange={(e) =>
                setFiltros({
                  ...filtros,
                  dataConsultaInicial: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            <label htmlFor="dataConsultaFinalFilter">
              Data consulta (até): &nbsp;&nbsp;
            </label>
            <InputText
              style={{ width: "100%", textAlign: "center" }}
              type="date"
              value={
                filtros?.dataConsultaFinal !== undefined
                  ? filtros.dataConsultaFinal
                  : ""
              }
              onChange={(e) =>
                setFiltros({
                  ...filtros,
                  dataConsultaFinal: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            <label htmlFor="tipoProntuarioFilter">
              Tipo de atendimento: &nbsp;&nbsp;
            </label>
            <Dropdown
              placeholder="Selecione um tipo de atendimento"
              name="tipoProntuarioFilter"
              id="tipoProntuarioFilter"
              style={{
                width: "100%",
                height: "50%",
                background: "#7988ac",
              }}
              value={
                filtroTipoProntuarioSelecionado.id !== undefined
                  ? filtroTipoProntuarioSelecionado
                  : {}
              }
              options={tiposProntuario}
              optionLabel="nome"
              emptyMessage="Não há informações"
              onChange={(e) => {
                setFiltroTipoProntuarioSelecionado(e.target.value);
                setFiltros({
                  ...filtros,
                  tipoAtendimentoId: e.target.value.id,
                });
              }}
            />
          </Col>
          <Col>
            <label htmlFor="finalizadoStatusFilter">
              Atendimento finalizado?: &nbsp;&nbsp;
            </label>
            <Dropdown
              placeholder="Selecione um status"
              name="finalizadoStatusFilter"
              id="finalizadoStatusFilter"
              style={{ width: "100%", background: "#7988ac" }}
              value={statusProntuarioSelecionado}
              options={status}
              optionLabel="nome"
              onChange={(e) => {
                setStatusProntuarioSelecionado(e.value);
                setFiltros({
                  ...filtros,
                  isFinalizado: e.value.valor,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <div className="FilterProntuarioItens">
            <DefaultButton
              fontSize={12}
              icon={<FiltrarIcon />}
              buttonLabel={"Filtrar"}
              messageToolTip={
                "Filtra os resultados de acordo com os parâmetros"
              }
              click={(_) => {
                filtrarAtendimentosPaciente(linhasPorPagina, pagina);
              }}
              width={150}
              height={30}
            />{" "}
            <DefaultButton
              fontSize={12}
              icon={<LimparIcon />}
              buttonLabel={"Limpar"}
              messageToolTip={"Limpar os filtros"}
              click={limpar}
              width={150}
              height={30}
            />{" "}
          </div>
        </Row>
        <CustomPaginateDataTable
          values={atendimentosDoPaciente}
          totalRecords={totalAtendimentosDoPaciente}
          dataKey="id"
          headerList={ProntuariosPacienteHeader}
          actionTemplate={actionTemplate}
          paginator={true}
          exibeActionTemplate={true}
          callbackFunction={filtrarAtendimentosPaciente}
          linhasPorPagina={linhasPorPagina}
          setLinhasPorPagina={setLinhasPorPaginaCallback}
          setPagina={setPaginaCallback}
          fieldToSort={"id"}
          showInLeft={true}
        />
      </div>

      <PrintProntuario
        ref={quillRef}
        dadosProntuario={prontuarioPacienteSelecionado}
      />
    </BlockUI>
  );
}
