import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

import PropTypes from "prop-types";

import DefaultButton from "../../../components/buttons/DefaultButton";
import { Password } from "primereact/password";
import "./RightInformations.css";
import DialogResetSenha from "../../reset-senha/DialogResetSenha";
import ResetSenhaService from "../../../service/ResetSenhaService";
import message from "../../../components/Messages";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export default function RightInformations(props) {
  const doLogin = props.doLogin;

  const [blockUi, setBlockUi] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [displayModalResetSenha, setDisplayModalResetSenha] = useState(false);

  function fecharModalResetSenha() {
    setDisplayModalResetSenha(false);
  }

  async function resetarSenha(email, token) {
    setDisplayModalResetSenha(false);
    setBlockUi(true);
    try {
      await ResetSenhaService.resetarSenha({
        email: email,
        captchaToken: token,
      });
      message.successMessage(
        "Senha resetada",
        "Aguarde e-mail com a nova senha!"
      );
      setBlockUi(false);
    } catch (error) {
      setBlockUi(false);
      message.errorMessage(
        "Erro ao resetar senha",
        error.response.data.message
      );
    }
  }

  return (
    <div className="col-5 flex align-items-center justify-content-center">
      <div className="p-fluid" style={{ width: 350 }}>
        <Card style={{ backgroundColor: "#5685a5" }}>
          <BlockUI
            width={"100%"}
            template={
              <ProgressSpinner
                strokeWidth="8"
                className="blue-spinner"
                style={{ width: "40px" }}
              />
            }
            blocked={blockUi}
          >
            <div className="field">
              <label
                style={{ color: "#24345a", fontFamily: "Syncopate" }}
                htmlFor="username"
              >
                Usuário
              </label>
              <InputText
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontFamily: "Syncopate",
                  textAlign: "left",
                }}
                id="username"
                type="text"
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
              />
            </div>
            <div className="field">
              <label
                style={{ color: "#24345a", fontFamily: "Syncopate" }}
                htmlFor="password"
              >
                Senha
              </label>
              <div className="RightInformations">
                <Password
                  style={{ width: "100%", textAlign: "left" }}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  toggleMask
                  feedback={false}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <button
                style={{
                  background: "none",
                  color: "#24345a",
                  border: "none",
                  textDecoration: "underline",
                  cursor: "pointer",
                  padding: 0,
                  font: "inherit",
                }}
                onClick={() => setDisplayModalResetSenha(true)}
              >
                Esqueci minha senha
              </button>
            </div>
            <DialogResetSenha
              display={displayModalResetSenha}
              esconder={fecharModalResetSenha}
              resetarSenha={resetarSenha}
            />
            <DefaultButton
              buttonLabel="Login"
              labelColor="#5685a5"
              backgroundColor="#fff"
              borderColor="#24345a"
              hoverColor="#24345a"
              onHoverBorderColor="#fff"
              onClickColor="#1e1f26"
              onClickBorderColor="#cc2a36"
              width={150}
              height={60}
              messageToolTip="Logar-se no sistema"
              click={() => doLogin(login, password)}
            />
          </BlockUI>
        </Card>
      </div>
    </div>
  );
}

RightInformations.propTypes = {
  doLogin: PropTypes.func,
};
