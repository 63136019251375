export class LogAcessoSistemaFiltrosRequestDTO {
  usuarioId?: number;
  statusAcesso?: string;
  dataAcessoInicio?: Date;
  dataAcessoFim?: Date;

  constructor(options: Partial<LogAcessoSistemaFiltrosRequestDTO> = {}) {
    this.usuarioId = options.usuarioId;
    this.statusAcesso = options.statusAcesso;
    this.dataAcessoInicio = options.dataAcessoInicio;
    this.dataAcessoFim = options.dataAcessoFim;
  }
}
