import PropTypes from "prop-types";

import "./DialogCRUDUsuario.css";

import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import {
  CancelIcon,
  SaveIcon,
  UsuarioIcon,
} from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import moment from "moment";

export default function DialogCRUDUsuario(props) {
  const {
    display,
    esconder,
    usuario,
    setUsuario,
    isVisualizacao,
    salvar,
    psicologos,
    perfis,
    isEdicao,
  } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<UsuarioIcon />} {"Cadastro e gerenciamento de usuários"}
      </div>
    );
  };

  function formatDate(date) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do usuário">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.id !== undefined ? usuario.id : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomeUsuario">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.nome !== undefined ? usuario.nome : ""}
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      nome: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="emailUsuario">
                  <b>E-mail: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="email"
                  value={usuario?.email !== undefined ? usuario.email : ""}
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      email: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="loginUsuario">
                  <b>Login: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isEdicao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="email"
                  value={usuario?.login !== undefined ? usuario.login : ""}
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      login: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioAtivo">
                  <b>Ativo: </b>
                </label>
                <br></br>
                <Checkbox
                  style={{ marginTop: "15px" }}
                  disabled={true}
                  inputId="bloqued"
                  checked={usuario?.ativo !== undefined ? usuario.ativo : ""}
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      ativo: e.target.value,
                    });
                  }}
                ></Checkbox>
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="usuarioCriacaoData"
                >
                  <b>Data de criação: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="email"
                  value={
                    usuario?.dataCriacao !== undefined
                      ? formatDate(usuario.dataCriacao)
                      : formatDate(new Date())
                  }
                />
              </Col>
            </Row>

            {!isEdicao && !isVisualizacao && (
              <Row>
                <Col>
                  <div className="field">
                    <label style={{ color: "#24345a" }} htmlFor="usuarioSenha">
                      <b>Senha: </b>
                    </label>
                    <br></br>
                    <div className="CrudUsuarioSenha">
                      <Password
                        promptLabel={"Informe uma senha"}
                        weakLabel={"Complexidade da senha: fraca"}
                        mediumLabel={"Complexidade da senha: média"}
                        strongLabel={"Complexidade da senha: forte"}
                        toggleMask
                        type="password"
                        onChange={(e) => {
                          setUsuario({
                            ...usuario,
                            senha: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="usuarioIsPsicologo"
                >
                  <b>Usuário psicólogo? </b>
                </label>
                <br></br>
                <Checkbox
                  style={{ marginTop: "15px" }}
                  disabled={isVisualizacao}
                  checked={
                    usuario?.isPsicologo !== undefined
                      ? usuario.isPsicologo
                      : false
                  }
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      isPsicologo: e.checked,
                    });
                  }}
                ></Checkbox>
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioPsicologo">
                  <b>Psicólogo: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao || !usuario.isPsicologo}
                  style={{ width: "100%", background: "#7988ac" }}
                  value={
                    usuario?.psicologo !== undefined ? usuario.psicologo : {}
                  }
                  options={psicologos}
                  optionLabel="nome"
                  emptyMessage="Não há psicologos cadastrados"
                  onChange={(e) => {
                    setUsuario({
                      ...usuario,
                      psicologo: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Perfis atrelados ao usuário">
          <div className="card">
            <MultiSelect
              itemClassName="CustomMultiSelect"
              disabled={isVisualizacao}
              value={
                usuario?.perfilAcessoList !== undefined
                  ? usuario.perfilAcessoList
                  : []
              }
              options={perfis}
              optionLabel="nome"
              placeholder="Selecione os perfis"
              filter
              className="multiselect-custom CustomMultiSelect"
              display="chip"
              onChange={(e) => {
                setUsuario({
                  ...usuario,
                  perfilAcessoList: e.target.value,
                });
              }}
            />
          </div>
        </Panel>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(usuario)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}

DialogCRUDUsuario.propTypes = {
  display: PropTypes.any,
  esconder: PropTypes.any,
  usuario: PropTypes.any,
  setUsuario: PropTypes.any,
  isVisualizacao: PropTypes.any,
  salvar: PropTypes.any,
  psicologos: PropTypes.any,
  perfis: PropTypes.any,
  isEdicao: PropTypes.any,
};
