import { Dialog } from "primereact/dialog";
import { PasswordIcon, UsuarioIcon } from "../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../components/buttons/DefaultButton";
import ReCAPTCHA from "react-google-recaptcha";
import { environment } from "../../environments/environment";
import If from "../../components/If";

export default function DialogResetSenha(props) {
  const { resetarSenha, display, esconder } = props;

  const env = environment.getEnvironment();

  const [email, setEmail] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<UsuarioIcon />} {"Resetar senha"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "50%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados de e-mail">
          <label style={{ fontWeight: "normal" }}>
            <b>Importante</b>: O e-mail informado deve ser o e-mail cadastrado
            junto a equipe de administração do PRONPSi. Após preenchimento das
            informações, se encontrado o usuário, uma nova senha será
            encaminhada para o e-mail informado.
          </label>
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="email">
                  <b>Informe o e-mail: </b>
                </label>
                <br></br>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <div className="field">
                  <ReCAPTCHA
                    sitekey={env.RECAPTCHA_TOKEN}
                    onChange={(token) => setCaptchaToken(token)}
                  />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <If condition={captchaToken && captchaToken !== null}>
                  <DefaultButton
                    fontSize={15}
                    icon={<PasswordIcon />}
                    buttonLabel={"Resetar"}
                    messageToolTip={
                      "Reseta a senha do usuário de acordo com o e-mail"
                    }
                    click={(_) => resetarSenha(email, captchaToken)}
                    width={150}
                    height={30}
                  />
                </If>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
